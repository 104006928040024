import { Box, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
// import moment from 'moment';
import { IntlContext } from 'src/App';
import './ui.scss';
import dayjs from 'dayjs';

const OnlineProcurement = ({ viewData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const date = dayjs(viewData?.start_time);
  require('dayjs/locale/de')
  const germanDate = date.locale('de').format('[am] DD. MMMM YYYY [um] H:mm [Uhr]');
  return (
    <Box className='online-procurement-sec'>
      {/* <Typography className='online-procurement-text'>
        {localesData?.banner_sec?.online_procurement_starts} 
        Die Online Auftragsvergabe startet {viewData?.start_time}
      </Typography> */}

      <Typography className='online-procurement-text'>
        {localesData?.banner_sec?.online_procurement_starts_on}{' '}
        {germanDate}
      </Typography>
    </Box>
  );
};

export default OnlineProcurement;
