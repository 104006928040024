import React from "react";
import { Navigate } from "react-router-dom";
// Layouts
import AuthLayout from "src/layouts/AuthLayout";
// Authentication
import LoginView from "src/view/pages/auth/SignIn";
import MainPage from "./view/pages/Main";
import CreateProductPage from "./view/pages/auth/CreateProduct";
// import FrontendConditionsPage from './view/pages/main/frontendconditions';
// import OrderNotStartedPage from './view/pages/main/orderNotStarted';
// import OrderPlacementStartedPage from './view/pages/main/orderPlacementStarted';
import Main from "./view/component/main";
import AcceptFrontendPricePage from "./view/pages/main/acceptFrontendPrice";
import PageNotFound from "./view/component/Pagenotfound";

//  private Routes
export const privateRoute = [
  {
    path: "/",
    element: <AuthLayout />,

    children: [
      { path: "*", element: <Navigate to="/" /> },
      { path: "/", element: <LoginView /> },
      { path: "/create-product", element: <CreateProductPage /> },
      { path: "/online-ordering/:id", element: <Main /> },
      { path: "/frontend-price/:id", element: <AcceptFrontendPricePage /> },
      { path: '/page-not-found', element: <PageNotFound /> },
    ],
  },
];

//  Public Route
export const publicRoute = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "*", element: <Navigate to="/" /> },
      { path: "/", element: <LoginView /> },
      { path: "/sign-in", element: <LoginView /> },
      { path: "/online-ordering/:id", element: <Main /> },
      { path: "/frontend-price/:id", element: <AcceptFrontendPricePage /> },
      { path: '/page-not-found', element: <PageNotFound /> },
    ],
  },
  {
    path: "/Main",
    element: <MainPage />,
  },
];
