import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  Grow,
  IconButton,
  Typography,
} from "@material-ui/core";
// import { ReactComponent as CloseIcon } from 'src/assets/images/icons/close.svg';
import CloseIcon from "@mui/icons-material/Close";
import "./ui.scss";

const DialogBox = ({
  handleClose,
  open,
  title,
  noTitle,
  content,
  className,
  crossHide
}) => {
  return (
    <Dialog
      className={`custom-dialog ${className}`}
      maxWidth="md"
      onClose={handleClose}
      open={open}
      TransitionComponent={Grow}
    >
      <Box className="dialog-content-box">
        {!crossHide &&
          <>
            <IconButton className="dialog-close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </>
        }
        <DialogTitle>
          <Box className="custom-dialog-title">
            <Typography className="dialog-title">
              {noTitle ? "" : title}
            </Typography>
          </Box>
        </DialogTitle>
      </Box>
      {content}
    </Dialog>
  );
};

export default DialogBox;
