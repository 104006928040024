import * as actionTypes from '../constants/StoreConstants';

const initialState = {
    isLoggedIn: false,
    token: null
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.storeConstants.SET_AUTHENTICATION:
            return {
                ...state,
                token: action.payload,
                isLoggedIn: true,
            };
        default:
            return state;
    }
};

export default AuthReducer;
