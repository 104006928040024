import React, { useContext } from 'react';
import Page404 from '../../../assets/images/404.svg';
import './pagenotfound.scss';
import { Box, Typography } from '@material-ui/core';
import { IntlContext } from 'src/App';

const PageNotFound = () => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <main className='pageNotMain'>
      <Box className='container'>
        <Box className='d-flex'>
          <Box className=''>
            <img src={Page404} alt='Page404' className='page-not' />
          </Box>
          <Box className='col-md-6 align-self-center'>
            <Typography variant='h1' className='pb-11'>
              404
            </Typography>
            <Typography variant='h2' className='pb-11'>
              {localesData?.validation?.Your}
            </Typography>
            <Typography>{localesData?.validation?.not}</Typography>
          </Box>
        </Box>
      </Box>
    </main>
  );
};

export default PageNotFound;
