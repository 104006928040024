import React from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Main from "../component/main";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));

const MainPage = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Online Auftragsvergabe">
      <Main />
    </Page>
  );
};

export default MainPage;
