import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";

import { Spinner } from "@chakra-ui/react";
const SignInComponent = React.lazy(() =>
  import("src/view/component/auth/Login/index")
);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));

const LoginView = () => {
  const classes = useStyles();

  return (
    <Suspense
      fallback={
        <div className="custom-loader">
          {" "}
          <Spinner />{" "}
        </div>
      }
    >
      <Page className={classes.root} title="Online Auftragsvergabe">
        <SignInComponent />
      </Page>
    </Suspense>
  );
};

export default LoginView;
