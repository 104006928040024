import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Banner from "../../UI/banner";
// import './frontendconditions.scss';
import { useParams } from "react-router-dom";
import ProjectName from "src/view/component/UI/projectName.js";
import OnlineProcurement from "../../UI/onlineprocurement";
import CurrentPrice from "../../UI/currentPrice";
import axiosInstance from "src/helpers/axios/axiosInstance";
import { URLS } from "src/helpers/constants/urls";
import { socket } from "src/helpers/context/socket";
import OrderPlacementStarted from "../orderPlacementStarted";
import { fetchFromStorage } from "src/helpers/context";
import { identifiers } from "src/helpers/constants/identifier";
import { IntlContext } from "src/App";

const OrderNotStarted = ({ onOpenForm }) => {
  const [viewData, setViewData] = useState();
  const { id } = useParams();
  const [sockedChecked, setSocketChecked] = useState(false);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  // GET PRODUCT
  const getproduct = async () => {
    try {
      // setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.getProduct + `/${id}`
      );

      if (status === 200) {
        setViewData(data?.data);
        if (data?.data?.status === "active") {
          setSocketChecked(true);
        }
        // setStateOptions(data?.data);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };
  useEffect(() => {
    getproduct();
    socket.on("ProductStarted", (data) => {
      // if (data && data?.product_id === viewData?.id) {
      setSocketChecked(true);
      // }
    });

    //eslint-disable-next-line
  }, []);

  return (
    <>
      {!sockedChecked ? (
        <Box className="order-not-started">
          <Banner title={localesData?.register?.notStart} />
          <Box>
            <Box className="container">
              <ProjectName viewData={viewData} />
              <CurrentPrice viewData={viewData} />
              <OnlineProcurement viewData={viewData} />
            </Box>
          </Box>
        </Box>
      ) : (
        <OrderPlacementStarted />
      )}
    </>
  );
};

export default OrderNotStarted;
