import { TextField, withStyles } from "@material-ui/core";

export const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#EFEFEF",
      color: "#64666B",
      borderRadius: 0,

      fontFamily: "Open Sans, sans-serif",
      "& .MuiOutlinedInput-input": {
        padding: "13px 15px",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #EFEFEF",
        // boxShadow:
        //   "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)",
      },
      "&:hover fieldset": {
        borderColor: "#EFEFEF",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #EFEFEF",
    },
    "&.Mui-disabled": {
      borderColor: "#EFEFEF",
      cursor: "not-allowed",
    },
  },
})(TextField);
