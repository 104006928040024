export const URLS = {
  // AUTH MODULE
  logIn: '/v1/public/auth/login',
  getProduct: '/v1/public/product/view',
  active: '/v1/private/product/active',
  createProduct: '/v1/private/product/create',
  getProfile: '/v1/private/admin/profile',
  participate: 'v1/public/product/participate',
  startProduct: 'v1/private/product/start',
  lock: 'v1/public/product/lock',
  bid: 'v1/public/product/bid',
  updateProduct: 'v1/private/product/edit',
  getcsv: 'v1/private/admin/email'
};
