import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import PreLoader from "src/components/loader";

const AcceptFrontendPriceComponent = React.lazy(() =>
  import("src/view/component/main/acceptFrontendPrice")
);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FAFAFB",
    height: "100%",
  },
}));

const AcceptFrontendPricePage = () => {
  const classes = useStyles();
  return (
    <Suspense
      fallback={
        <div>
          {" "}
          <PreLoader />{" "}
        </div>
      }
    >
      <Page className={classes.root} title="Online Auftragsvergabe">
        <AcceptFrontendPriceComponent />
      </Page>
    </Suspense>
  );
};

export default AcceptFrontendPricePage;
