import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core";
import Page from "src/components/Page";

import PreLoader from "src/components/loader";
const CreateProduct = lazy(() =>
  import("src/view/component/auth/CreateProduct")
);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
  },
}));
const renderLoader = () => (
  <>
    <PreLoader />
  </>
);

const CreateProductPage = () => {
  const classes = useStyles();

  return (
    <Suspense fallback={renderLoader()}>
      <Page className={classes.root} title="Online Auftragsvergabe">
        <CreateProduct />
      </Page>
    </Suspense>
  );
};

export default CreateProductPage;
