import React from 'react';
import { Box } from '@material-ui/core';
import { ReactComponent as LogoIcon } from 'src/assets/images/logo.svg';

const Logo = (props) => {
  return (
    <Box className='app-logo logo'>
      <LogoIcon />
    </Box>
  );
};

export default Logo;
