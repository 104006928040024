import { Box, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { IntlContext } from "src/App";
import "./ui.scss";

const ProjectName = ({ viewData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className="description-sec">
      <Typography className="desc-title-text pb-22">
        {/* {localesData?.banner_sec?.project_name}  */}
        {viewData?.title}
      </Typography>
      <Typography className="desc-para-text pb-22">
        {/* {localesData?.banner_sec?.description} */}
        {viewData?.description}
      </Typography>
      <Typography className="desc-para-text">
        {/* {localesData?.banner_sec?.description2} */}
      </Typography>
    </Box>
  );
};

export default ProjectName;
