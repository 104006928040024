import { Box, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import Logo from 'src/components/Logo';
import { IntlContext } from 'src/App';

const Banner = ({ title }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  return (
    <Box className='banner-sec' >
      <Box className='container'>
        <Logo />
        <Typography className='title-text'>
          {localesData?.banner_sec?.online_ordering}
        </Typography>
        <Typography className='para-text' >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default Banner;
