import { Box, Button, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Banner from '../../UI/banner';
// import './frontendconditions.scss';
import { IntlContext } from 'src/App';
import ProjectName from 'src/view/component/UI/projectName.js';
import CurrentPrice from '../../UI/currentPrice';
import DialogBox from '../../UI/dialogBox';
import './orderPlacementStarted.scss';
import axiosInstance from 'src/helpers/axios/axiosInstance';
import { setApiMessage } from 'src/helpers/commonFunctions';
import { URLS } from 'src/helpers/constants/urls';
import { useNavigate, useParams } from 'react-router-dom';
import { socket } from 'src/helpers/context/socket';
import { fetchFromStorage, saveToStorage } from 'src/helpers/context';
import { identifiers } from 'src/helpers/constants/identifier';

const OrderPlacementStarted = ({ onOpenForm }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [viewData, setViewData] = useState();
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();
  const [acceptPrice, setAcceptPrice] = useState(false);
  const [price, setPrice] = useState(0);
  const [openForIntrest, setOpenForIntrest] = useState(false);
  const { id } = useParams();

  // GET PRODUCT
  const getproduct = async () => {
    try {
      // setLoader(true);
      const { status, data } = await axiosInstance.get(
        URLS.getProduct + `/${id}`
      );
      console.log('datadata', data);
      if (status === 200) {
        setViewData(data?.data);
        // setStateOptions(data?.data);
        // setLoader(false);
      }
    } catch (error) {
      // setLoader(false);
    }
  };


  useEffect(() => {
    getproduct();
    socket.on('ProductLocked', (data) => {
      setAcceptPrice(true);
      setOpenForIntrest(!openForIntrest);
    });
    socket.on('priceUpdate', (data) => {
      console.log('priceUpdate', data);
      getproduct()
      setPrice(data?.amount);
    });

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleWindowBlur = () => {
      setOpen(true);
    };

    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);
  const handleClosePopup = () => {
    setOpen(false);
  };


  const bid = async () => {
    let sendData = {
      product_id: id,
      user_id: fetchFromStorage(identifiers?.participateData)?.id,
      amount: price === 0 ? viewData?.start_price : price,
    };
    try {
      const { status, data } = await axiosInstance.post(URLS.bid, sendData, {
        headers: {
          'Accept-Language': fetchFromStorage(identifiers.acceptlanguage),
        }
      });
      if (status === 200) {
        if (data?.status) {
          // setApiMessage('success', data?.message);
          // console.log('object', data?.data?.amount);
          saveToStorage(identifiers.amount, data?.data?.amount);
          setFlag(false);
          navigate(`/frontend-price/${id}`)
        } else {
          setApiMessage('error', data?.message);
        }
      }
    } catch (error) {
      setApiMessage('error', error?.response?.data?.message);
    }
  };
  return (
    <>
      <Box className='order-not-started'>
        <Banner title={localesData?.register?.getStart} />
        <Box>
          <Box className='container'>
            <ProjectName viewData={viewData} />
            <CurrentPrice viewData={viewData} />
            <Box className='continue-sec'>
              <Button
                variant='contained'
                className='continue-btn'
                // onClick={() => setOpen(!open)}
                onClick={() => {
                  // setOpen(!open);
                  bid();
                }}
                disabled={acceptPrice}
              >
                {localesData?.banner_sec?.accept_price_now}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {open && (
        <DialogBox
          handleClose={handleClosePopup}
          open={open}
          title={localesData?.banner_sec?.danger}
          crossHide={false}
          content={
            <>
              <Box>
                {/* <Typography className='dialog-title'>
                {localesData?.banner_sec?.danger}
              </Typography> */}
                <Typography className='dialog-para'>
                  {localesData?.banner_sec?.order_participate}
                </Typography>
              </Box>
            </>
          }
        />
      )}
      <DialogBox
        // handleClose={() => setOpenForIntrest(!openForIntrest)}
        crossHide={true}
        open={openForIntrest}
        title={localesData?.banner_sec?.thank_uou_for_your_interest}
        content={
          <>
            <Box>
              {/* <Typography className='dialog-title'>
                  {localesData?.banner_sec?.danger}
                </Typography> */}
              <Typography className='dialog-para'>
                {localesData?.banner_sec?.online_ordering_has_ended}
              </Typography>
            </Box>
          </>
        }
      />
    </>
  );
};

export default OrderPlacementStarted;
